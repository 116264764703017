import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../store/root';
import { IAsyncActionState } from '../utils';
import { IFirebaseUser, ITour } from '../../models';
import { commonActions } from '../common';
import actions from './actions';

export interface IAppState extends IAsyncActionState {
  user?: IFirebaseUser;
  authorities: string[];
  allowedLocationTracking: boolean;
  hasCompletedCreateTourOnboarding: boolean;
  offlineTours: Record<string, ITour>;
  showUpdateDialog: boolean;
  isAppUpdateAvailable: boolean;
}

const defaultState: IAppState = {
  isLoading: false,
  allowedLocationTracking: false,
  offlineTours: {},
  hasCompletedCreateTourOnboarding: false,
  showUpdateDialog: false,
  isAppUpdateAvailable: false,
  authorities: [],
};

const appReducer = createReducer<IAppState, RootAction>(defaultState)
  .handleAction(commonActions.updateUser, (state, action) =>
    produce(state, draft => {
      draft.user = action.payload;
    })
  )
  .handleAction(commonActions.signIn.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.error = undefined;
    })
  )
  .handleAction(commonActions.signIn.success, (state, action) =>
    produce(state, draft => {
      draft.user = action.payload;
      draft.isLoading = false;
      draft.error = undefined;
    })
  )
  .handleAction(commonActions.signIn.failure, (state, action) =>
    produce(state, draft => {
      draft.error = action.payload;
      draft.isLoading = false;
    })
  )
  .handleAction(commonActions.allowLocationTracking, (state, action) =>
    produce(state, draft => {
      draft.allowedLocationTracking = action.payload.allowTracking;
    })
  )
  .handleAction([commonActions.fetchTourAsync.success], (state, action) =>
    produce(state, draft => {
      draft.offlineTours[action.payload.shortLink!] = action.payload;
    })
  )
  .handleAction([commonActions.removeTour], (state, action) =>
    produce(state, draft => {
      delete draft.offlineTours[action.payload];
    })
  )
  .handleAction(commonActions.completeCreateTourOnboarding, (state, action) =>
    produce(state, draft => {
      draft.hasCompletedCreateTourOnboarding = true;
    })
  )
  .handleAction(actions.appUpdate.request, (state, action) =>
    produce(state, draft => {
      draft.showUpdateDialog = true;
    })
  )
  .handleAction(actions.appUpdate.cancel, (state, action) =>
    produce(state, draft => {
      draft.showUpdateDialog = false;
    })
  )
  .handleAction(actions.appUpdate.updateAvailable, (state, action) =>
    produce(state, draft => {
      draft.isAppUpdateAvailable = true;
    })
  )
  .handleAction(actions.setAuthorities, (state, action) =>
    produce(state, draft => {
      draft.authorities = action.payload.authorities;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
    isLoading: state.isLoading,
  }));

export default appReducer;
