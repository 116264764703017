import { createAction, createAsyncAction } from 'typesafe-actions';
import { DialogConfig, SnackbarConfig } from './models';
import { IFirebaseUser, ITour, ITourProgress, TourCode } from '../../models';
import { IAsyncError, ICreateUpdateTourReviewRequest } from '../../common';

interface CreateTourReviewRequest extends ICreateUpdateTourReviewRequest {
  tourCode: string;
  silent: boolean;
  onSuccess?: () => void;
}

const initApp = createAction('@app/INIT');

const showDialog = createAction('@common/SHOW_DIALOG', action => {
  return (dialogConfig: DialogConfig) => action({ dialogConfig });
});

const hideDialog = createAction('@common/HIDE_DIALOG', action => {
  return (confirmed?: boolean) => action(confirmed);
});

const showSnackbar = createAction('@common/SHOW_SNACKBAR', action => {
  return (snackbarConfig: SnackbarConfig) => action({ snackbarConfig });
});

const hideSnackbar = createAction('@common/HIDE_SNACKBAR');

const allowLocationTracking = createAction('@common/ALLOW_LOCATION_TRACKING', action => {
  return (allowTracking: boolean) => action({ allowTracking });
});

const fetchTourAsync = createAsyncAction(
  '@common/FETCH_TOUR_REQUEST',
  '@common/FETCH_TOUR_SUCCESS',
  '@common/FETCH_TOUR_FAILURE',
  '@common/FETCH_TOUR_CANCEL'
)<string, ITour, IAsyncError, void>();

const removeTour = createAction('@common/REMOVE_TOUR', action => (tourCode: string) => action(tourCode));

const cancelTour = {
  request: createAction('@common/CANCEL_TOUR_REQUEST', action => {
    return (tourCode: string, onConfirmed?: () => void) => action({ tourCode, onConfirmed });
  }),
  confirmed: createAction('@common/CANCEL_TOUR_CONFIRMED', action => {
    return (tourCode: string, tourStop?: number) => action(tourCode, { tourStop });
  }),
};

const clearAssetCache = createAction('@common/CLEAR_ASSET_CACHE', action => (tourCode: string) => action(tourCode));

const deleteTour = {
  request: createAction('@common/DELETE_TOUR_REQUEST', action => {
    return (tourCode: string) => action(tourCode);
  }),
  success: createAction('@common/DELETE_TOUR_SUCCESS', action => {
    return (tourCode: string) => action(tourCode);
  }),
  failure: createAction('@common/DELETE_TOUR_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
};

const synchronizeTourProgress = createAsyncAction(
  '@common/SYNC_TOUR_PROGRESS_REQUEST',
  '@common/SYNC_TOUR_PROGRESS_SUCCESS',
  '@common/SYNC_TOUR_PROGRESS_FAILURE',
  '@common/SYNC_TOUR_PROGRESS_CANCEL'
)<void, ITourProgress[], IAsyncError, void>();

const signIn = {
  request: createAction('@common/SIGN_IN_REQUEST'),
  success: createAction('@common/SIGN_IN_SUCCESS', action => {
    return (user: IFirebaseUser) => action(user);
  }),
  failure: createAction('@common/SIGN_IN_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
  cancel: createAction('@common/SIGN_IN_CANCEL'),
};

const signInAnonymously = createAction('@common/SIGN_IN_ANONYMOUSLY_REQUEST');

const signOut = createAsyncAction('@common/SIGN_OUT_REQUEST', '@common/SIGN_OUT_SUCCESS', '@common/SIGN_OUT_FAILURE')<
  void,
  void,
  IAsyncError
>();

const connection = {
  online: createAction('@common/ONLINE'),
  offline: createAction('@common/OFFLINE'),
};

const updateUser = createAction('@common/UPDATE_USER', action => {
  return (user: IFirebaseUser) => action(user);
});

const updateAuthorities = createAction('@common/UPDATE_AUTHORITIES');

const reauthenticate = {
  showDialog: createAction('@common/REAUTHENTICATE_SHOW'),
  ...createAsyncAction(
    '@common/REAUTHENTICATE_REQUEST',
    '@common/REAUTHENTICATE_SUCCESS',
    '@common/REAUTHENTICATE_FAILURE',
    '@common/REAUTHENTICATE_CANCEL'
  )<string, void, IAsyncError, void>(),
};

const resetPassword = createAsyncAction(
  '@common/RESET_PASSWORD_REQUEST',
  '@common/RESET_PASSWORD_SUCCESS',
  '@common/RESET_PASSWORD_FAILURE'
)<void, void, IAsyncError>();

const completeCreateTourOnboarding = createAction('@common/COMPLETE_CREATE_TOUR_ONBOARDING');

const createTourReview = createAsyncAction(
  '@common/CREATE_TOUR_REVIEW_REQUEST',
  '@common/CREATE_TOUR_REVIEW_SUCCESS',
  '@common/CREATE_TOUR_REVIEW_FAILURE',
  '@common/CREATE_TOUR_REVIEW_CANCEL'
)<CreateTourReviewRequest, TourCode, IAsyncError, void>();

export default {
  initApp,
  showDialog,
  hideDialog,
  showSnackbar,
  hideSnackbar,
  allowLocationTracking,
  fetchTourAsync,
  removeTour,
  cancelTour,
  deleteTour,
  synchronizeTourProgress,
  signIn,
  signOut,
  connection,
  updateUser,
  reauthenticate,
  resetPassword,
  completeCreateTourOnboarding,
  createTourReview,
  clearAssetCache,
  signInAnonymously,
  updateAuthorities,
};
